const initialState = {
    isLoaded: false,
    data: undefined
};
const productReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRODUCT':
            return {
                isLoaded: true,
                data: action.payload
            };
        default:
            return state;
    }
}

export { initialState as productState, productReducer }
