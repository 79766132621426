import styled from "styled-components";
import React from "react";

const MainText = styled.h2`
    font-size: 2rem;
    line-height: 2;
    text-align: center;
`;

const PriceText = styled(MainText)`
    font-weight: bold;
`;

const Text = styled.h3`
    font-size: 1.6rem;
    line-height: 1.2;
    text-align: center;
`;

const SecondaryText = styled.span`
    font-size: 1.6rem;
    line-height: 1.2;
    color: #999999;
    text-align: center;
`;

const SecondaryTextBlack = styled(SecondaryText)`
    color: #000000;
`;

const SecondaryTextBlackBold = styled(SecondaryTextBlack)`
    font-weight: bold;
`;

const PromoText = styled(SecondaryText)`
  margin-bottom: 1rem;
`;

const ActionButton = styled.button`
    margin: 30px;
    background-color: ${props => props.disabled ? "#000000" : "#FF7900"};
    color: #FFFFFF;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2;
`;


const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 0;
  line-height: 2rem;
  right: 10px;
  top: 5px;
  border: none;
  background: none;
  color: inherit;
  opacity: ${props => props.disabled ? "0.5" : "1" };
  font: inherit;
  outline: inherit;
`;

const HeaderPopup = styled.div`
  width: calc(100% - 10px);
  text-align: center;
  padding: 5px;
`;

const ContentPopup = styled.div`
  width: calc(100% - 10px);
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
    font: inherit;
    font-size: 2rem;
    padding: 0.6rem;
    margin: 10px 30px 5px;
    box-shadow: inset 0px 0px 5px 0px rgba(238,238,238,1);
    border-radius: 5px;
    border: 1px solid #EEEEEE;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const ErrorText = styled.span`
    color: #CD3C14;
    font-size: 1.2rem;
    margin: 0px 30px;
`;

const Link = styled.a`
    color: black;
`

const ErrorIcon = () =>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="red"
    >
        <title>Error Icon</title>
        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z"></path>
    </svg>;


const CloseIcon = () =>
    <svg
        width="10"
        height="10"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>Close Icon</title>
        <path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path>
    </svg>;

const SuccessIcon = () =>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="green"
    >
        <title>Success Icon</title>
        <path
            d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z"></path>
    </svg>;

const Container = styled.div`
    display: grid;
    grid-template-areas: 
        "header"
        "content";
    grid-template-columns: auto;
    grid-template-rows: 1fr 10fr;
    max-height: 100%;
`;

const Content = styled.div`
    grid-area: content;
    background-color: #FFFFFF;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`;

const FitToWindow = styled.div`
    background-color: #EEEEEE;
    max-width: 600px;
    margin: 10px;
`


export {
    HeaderPopup,
    ContentPopup,
    SuccessIcon,
    CloseIcon,
    ErrorIcon,
    CloseButton,
    MainText,
    PromoText,
    PriceText,
    Text,
    SecondaryText,
    SecondaryTextBlack,
    SecondaryTextBlackBold,
    ActionButton,
    FormInput,
    Form,
    ErrorText,
    Link,
    Container,
    Content,
    FitToWindow
}
