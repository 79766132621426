import React, { useState, useEffect } from 'react';
import config from './config';

const ConfigLoader = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        load().then(() => setIsLoaded(true));
    }, []);

    const load = () => {
        return fetch('./config.json')
            .then(response => response.json())
            .then(newConfig => {
                Object.assign(config, newConfig);
                return config;
            });
    };

    return isLoaded ? <>{props.children}</> : null;
};

export default ConfigLoader;
