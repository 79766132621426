import React, {useContext} from 'react';
import {ContentPopup, HeaderPopup, Link, SuccessIcon, Text} from "../../components/commons/Commons";
import {useTranslation} from "react-i18next";
import Popup from "../../components/Popup";
import {GlobalContext} from "../../stores/GlobalStore";
import createRedirectUrl from "../../common/createRedirectUrl";
import config from "../../components/config/config";
import {useLocation} from "react-router-dom";


const SuccessPopup = (props) => {
    const {state} = useContext(GlobalContext);
    const {product, user} = state;
    const {t} = useTranslation();
    const location = useLocation();
    const redirectUrl = createRedirectUrl(user.data, true, product.data, location);

    const onOpen = () => {
        console.log("Success open");
        setTimeout(() => {
            console.log("Redirect url " + redirectUrl);
            window.location = redirectUrl;
        }, config.popUpDisplayTime);
    };
    const isProductOneOff = product?.data?.productType === 'ONEOFF';

    return (
        <Popup
            open={props.isOpen}
            onOpen={onOpen}
            modal
            closeOnDocumentClick={false}
            success
        >
            <div className="modal">
                <HeaderPopup>
                    <SuccessIcon/>
                </HeaderPopup>
                <ContentPopup>
                    <Text>
                        {isProductOneOff ? t('text10.oneoff') : t('text10.subscription')}
                        <br/>
                        {t('text11')}<Link href={redirectUrl}>{t('text11.link')}</Link>.
                    </Text>
                </ContentPopup>
            </div>
        </Popup>
    )
};

export default SuccessPopup;
