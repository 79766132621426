import React, {useContext, useEffect} from "react";
import {GlobalContext} from "../GlobalStore";
import {loadProduct} from "./ProductService";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const findTokenServiceId = user => {
    return user ? user.data.serviceId : null;
}

const findUrlServiceId = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("serviceId");
}

const findServiceId = (user, location) => {
    const tokenServiceId = findTokenServiceId(user);
    if (tokenServiceId) {
        return tokenServiceId;
    }
    return findUrlServiceId(location);
}

const ProductLoader = props => {
    const {state, dispatch} = useContext(GlobalContext);
    const {user} = state;
    const {t} = useTranslation();
    const location = useLocation();
    const serviceId = findServiceId(user, location)

    useEffect(() => {
        if (state.product.isLoaded) {
            return;
        }
        if (serviceId) {
            loadProduct(serviceId)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Error loading product");
                    }
                })
                .then(productData => {
                    dispatch({
                        type: "SET_PRODUCT",
                        payload: productData
                    });
                }).catch(() => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {message: t('error.LoadingProduct')}
                });
            });
        } else {
            dispatch({
                type: "SET_ERROR",
                payload: {message: t('error.LoadingProduct')}
            });
        }
    }, [t, serviceId, dispatch, state.product.isLoaded]);

    return <>{props.children}</>;
}

export default ProductLoader;
