import { useState, useEffect } from 'react';
import config from "../config/config";

const useVersions = () => {
    const [backendVersion, setBackendVersion] = useState('');
    const frontendVersion = process.env.REACT_APP_VERSION;
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        fetch(`${config.apiUrl}/version`)
            .then(response => response.text())
            .then(data => setBackendVersion(data))
            .catch(error => console.error('Error fetching version:', error));
    }, []);

    return { backendVersion, frontendVersion, currentYear };
};

export default useVersions;