import {get} from "../../common/fetchWrapper";

async function decodeToken(token) {
    return await get('/payment/decrypt-token', token);
}

const parseUserToken = async (token) => {
    return await decodeToken(token)
        .then(function (response) {
            return response.json()
        });
};


export {
    parseUserToken
}
