import React from "react";
import PaymentView from "../views/payment/PaymentView";
import OtpView from "../views/otp/OtpView";
import ErrorView from "../views/error/ErrorView";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {FitToWindow} from "../components/commons/Commons";
import LanguageSetter from "../stores/language/LanguageSetter";
import UserLoader from "../stores/user/UserLoader";
import ProductLoader from "../stores/product/ProductLoader";
import PrivacyNoticeView from "../views/privacyNotice/privacyNoticeView";
import config from "../components/config/config";

export default function Router() {
    return (
        <BrowserRouter basename={config.basename}>
            <UserLoader>
                <LanguageSetter>
                    <ProductLoader>
                        <FitToWindow>
                            <Routes>
                                <Route path="payment" element={<PaymentView/>}/>
                                <Route path="otp" element={<OtpView/>}/>
                                <Route path="error" element={<ErrorView/>}/>
                                <Route path="*" element={<ErrorView/>}/>
                                <Route path="privacy_notice" element={<PrivacyNoticeView/>}/>
                        </Routes>
                    </FitToWindow>
                </ProductLoader>
            </LanguageSetter>
        </UserLoader>
</BrowserRouter>
)
    ;
}
