import React from "react";
import Router from "./navigation/Router";
import {StateProvider} from "./stores/GlobalStore";
import ErrorPopup from "./views/paymentPopups/ErrorPopup";
import {FitToWindow} from "./components/commons/Commons";
import ConfigLoader from "./components/config/ConfigLoader";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false
        }
    }

    componentDidCatch(error, info) {
        this.setState({
            isError: true
        });
        console.log("Component did catch");
        console.log(error);
        console.log(info);
    }

    render() {
        if (this.state.isError) {
            return <FitToWindow>
                <ErrorPopup isOpen={true}/>
            </FitToWindow>
        }
        return (
            <StateProvider>
                <ConfigLoader>
                    <Router/>
                </ConfigLoader>
            </StateProvider>
        );
    }
}

export default App;
