import {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {GlobalContext} from "../GlobalStore";
import {parseUserToken} from "./UserService";
import {useTranslation} from "react-i18next";

const UserLoader = props => {
    const location = useLocation();
    const {t} = useTranslation();
    const {dispatch} = useContext(GlobalContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(()=>{
        const loadData = async () => {
            try {
                if (isLoaded) {
                    return;
                }
                const searchParams = new URLSearchParams(location.search);
                const token = searchParams.get("token");
                if (token) {
                    dispatch({
                        type: "SET_USER",
                        payload: {
                            data: await parseUserToken(token),
                            token
                        }
                    });
                }
            } catch (e) {
                dispatch({
                    type: "SET_ERROR",
                    payload: {message: t('Error parsing user token')}
                });
            }
        }
        loadData()
            .then(() => setIsLoaded(true));
    }, [location, dispatch, t, isLoaded]);

    return isLoaded ? <>{props.children}</> : null;
}

export default UserLoader;
