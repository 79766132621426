import config from "../components/config/config";
import {v4 as uuidv4} from "uuid";


const SESSION_ID = uuidv4();

const get = (url, authorization) => {
    const endpoint = config.apiUrl + url;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${authorization}`,
            'X-SessionId': SESSION_ID
        },
    };
    return fetch(endpoint, options);
};

const getNoAuth = (url) => {
    const endpoint = config.apiUrl + url;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'X-SessionId': SESSION_ID
        },
    };
    return fetch(endpoint, options);
};

const post = (url, authorization, body, language) => {
    const endpoint = config.apiUrl + url;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'Authorization': `Bearer ${authorization}`,
            'X-SessionId': SESSION_ID
        },
        body: JSON.stringify(body)
    };
    return fetch(endpoint, options);
};


const postNoAuth = (url, body) => {
    const endpoint = config.apiUrl + url;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-SessionId': SESSION_ID
        },
        body: JSON.stringify(body)
    };
    return fetch(endpoint, options);
};

export {
    get,
    getNoAuth,
    post,
    postNoAuth
}
