const otpErrorReasonToMessage = (t, reason, productData) => {
    switch (reason) {
        case "PRODUCT_ONEOFF_HYBRID" :
            return t("error.ProductIsHybrid", {internalId: productData.internalId, externalId: productData.externalId});
        case "USER_NOT_ELIGIBLE_ERROR" :
            return t("error.IneligibleUser");
        default:
            return t("error.TechnicalError");
    }
}

const otpErrorToMessage = (error) => {
    switch (error.errorCode) {
        case 'aa345bfa-3209':
            return 'error.otp.IneligibleUser';
        case '3540203':
            return 'error.otp.IneligibleUser';
        case '7ebe2ab5-7d1f':
            return 'error.otp.InvalidConfirmationCode';
        case 'de55248b-a629':
            return 'error.otp.TooManyRetries';
        case 'b5c407d8-9c0f':
            return 'error.otp.InvalidMsisdn';
        case '313b95a4-0492':
            return 'error.otp.TechnicalError';
        default:
            return error.message;
    }
}

export {otpErrorReasonToMessage, otpErrorToMessage};
