import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../stores/GlobalStore";
import {useTranslation} from "react-i18next";
import {Container} from "../../components/commons/Commons";
import OrangeLogo from "../../components/header/orange_logo.jpg";
import './privacyNotice.css';
import PrivacyNoticeFooter from "../../components/footer/PrivacyNoticeFooter";


const PrivacyNoticeView = () => {
    const {state} = useContext(GlobalContext);
    const {t} = useTranslation();
    const [privacyNotice, setPrivacyNotice] = useState(null);
    const {language} = state.language;
    useEffect(() => {
        fetch(`privacyNotice/${language}.json`)
            .then(response => {
                return response.json();
            })
            .then(data => setPrivacyNotice(data.privacyNotice))
            .catch(error => console.error('Error fetching privacy notice:', error));
    }, [language]);

    const renderElement = (element) => {
        switch (element.type) {
            case 'title':
                return <h1>{element.value}</h1>
            case 'header':
                return <h2>{element.value}</h2>;
            case 'paragraph':
                return <p dangerouslySetInnerHTML={{__html: element.value}}/>;
            case 'list':
                return (
                    <ul>
                        {element.items.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                );
            case 'table':
                return (
                    <table>
                        <thead>
                        <tr>
                            {element.columns.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {element.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>
                                        {Array.isArray(cell) ? (
                                            <ul>
                                                {cell.map((item, itemIndex) => (
                                                    <li key={itemIndex}>{item}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className="plain-text">{cell}</span>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                );
            default:
                return null;
        }
    };

    return (
        <Container>
            <div className="heading-container">
                <div className="image-container">
                    <img className="image" src={OrangeLogo} alt={t('Orange Logo')}/>
                </div>
                <div className="title-container">
                    <h1 className="title">{t('text13')}</h1>
                </div>
            </div>
            <div className="privacy-notice-box">
                {privacyNotice ? (
                    privacyNotice.elements.map((element, index) => (
                        <div key={index}>
                            {renderElement(element)}
                        </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            <PrivacyNoticeFooter/>
        </Container>
    );
};

export default PrivacyNoticeView;
