const getPeriodicityText = (t, numericalPeriodicity) => {
    switch (numericalPeriodicity) {
        case 86400 :
            return t("daily");
        case 604800 :
            return t("weekly");
        case 0:
            return t("monthly");
        default:
            return "";
    }
}

export default getPeriodicityText;
