const initialState = {
    isLoaded: false,
    data: {
        serviceId: undefined
    },
    token: undefined
};
const userReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                isLoaded: true,
                token: action.payload.token,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export { initialState as userState, userReducer }
