import React from "react";
import styled from "styled-components";
import {ActionButton, Container, Content, MainText, SecondaryText} from "../../components/commons/Commons";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PartnerButton = styled(ActionButton)`
    background-color: ${props => props.disabled ? "#000000" : "#FF7900"};
`

const ErrorView = () => {
    const location = useLocation();
    const {t} = useTranslation();

    const goBack = () => {
        location.history.back();
    };

    return <Container>
        <Content>
            <MainText>{t('404')}</MainText>
            <MainText>{t('pageNotFound.mainText')}</MainText>
            <SecondaryText>{t('pageNotFound.secondaryText')}</SecondaryText>
            {location.history &&
            <>
                <PartnerButton id="goBackButton" onClick={goBack}>{t('pageNotFound.goBack')}</PartnerButton>
            </>
            }
        </Content>
    </Container>;
}

export default ErrorView;
