import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en";
import frTranslation from "./translations/fr";
import nlTranslation from "./translations/nl";

const resources = {
    en: {
        translation: enTranslation
    },
    fr: {
        translation: frTranslation
    },
    nl: {
        translation: nlTranslation
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
