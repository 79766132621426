import React from "react";
import SuccessPopup from "./SuccessPopup";
import ErrorPopup from "./ErrorPopup";


const PaymentPopups = (props) => {

    return <>
        <SuccessPopup isOpen={props.isSuccess}/>
        <ErrorPopup isOpen={props.isError} message={props.errorMessage}/>
    </>
}

export default PaymentPopups;
