import {SecondaryText} from "../commons/Commons";
import React from "react";
import styled from "styled-components";
import LanguageSelector from "./LanguageSelector";
import config from "../config/config";
import useVersions from "../commons/useVersions";


const LanguageContainer = styled.div`
    grid-area: languages;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VersionInfoContainer = styled.div`
    grid-area: version;
    text-align: center;
    justify-content: center;
    margin-bottom: 2.5%;
`

const FooterContainer = styled.div`
    grid-template-areas:
           "languages"
           "version";
    margin: 10px 0px 10px;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

`;


const VersionText = styled(SecondaryText)`
    display: block;
    font-size: 1.1rem;
    color: #999;
`;

const PrivacyNoticeFooter = () => {
    const { backendVersion, frontendVersion, currentYear } = useVersions(config.apiUrl);

    return <FooterContainer>
        <LanguageContainer>
            <LanguageSelector/>
        </LanguageContainer>
        <VersionInfoContainer>
            <VersionText>BE: {backendVersion} FE: {frontendVersion}</VersionText>
            <VersionText>© {currentYear} Orange</VersionText>
        </VersionInfoContainer>
    </FooterContainer>
};

export default PrivacyNoticeFooter;
