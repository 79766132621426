import {getNoAuth} from "../../common/fetchWrapper";

const loadProduct = async (serviceId) => {
    return getNoAuth(`/products/${serviceId}`);
};



export {
    loadProduct
}
