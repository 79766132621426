const initialState = {
    isSelectedByUser: false,
    language: 'fr'
};
const languageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE_USER_ACTION':
            console.log("SET_LANGUAGE_USER_ACTION")
            return {
                isSelectedByUser: true,
                language: action.payload.toLowerCase()
            };
        case 'SET_LANGUAGE_URL_ACTION':
            console.log("SET_LANGUAGE_URL_ACTION")
            if (state.isSelectedByUser) {
                return state;
            }
            return {
                isSelectedByUser: false,
                language: action.payload.toLowerCase()
            };
        default:
            return state;
    }
}

export {initialState as languageState, languageReducer}
