import styled from "styled-components";
import { Popup as InitialPopup } from "reactjs-popup";

const Popup = styled(InitialPopup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-content {
    border: 2px solid ${props => props.success ? "#008000" :
                                   props.error ? "red" :
                                                 "#000000"};
    border-radius: 15px;
    background: rgb(255, 255, 255);
    padding: 5px;
    width: calc(100% - 10px);
    max-width: 600px;
  }

  &-content > .modal {
    font-size: 1.2rem;
`;

export default Popup;
