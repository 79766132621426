import {SecondaryText} from "../commons/Commons";
import React, {useContext} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import createRedirectUrl from "../../common/createRedirectUrl";
import {GlobalContext} from "../../stores/GlobalStore";
import {Link, useLocation} from "react-router-dom";
import parseSearchParams from "../../common/parseSearchParams";
import LanguageSelector from "./LanguageSelector";
import config from "../config/config";
import useVersions from "../commons/useVersions";


const LanguageContainer = styled.div`
    grid-area: languages;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SecondaryTextContainer = styled.div`
    grid-area: text;
    text-align: center;
    justify-content: center
`;

const CancelLinkContainer = styled.div`
    grid-area: cancel;
    text-decoration: underline;
    text-align: center;
    margin: 10px 0px 10px;
`;

const PrivacyNoticeContainer = styled.div`
    grid-area: privacy-notice;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 10px;
`

const CancelLink = styled.a`
    text-decoration-color: black;
`;

const VersionInfoContainer = styled.div`
    grid-area: version;
    text-align: center;
    justify-content: center;
    margin-bottom: 2.5%;
`

const FooterContainer = styled.div`
    grid-template-areas: 
           "text text text text text"
           "privacy-notice . cancel . languages"
           ". . cancel . ."
           ". version version version .";
    margin: 10px 0px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;

`;

const FooterText = styled(SecondaryText)`
    font-size: 1.2rem;
    color: black;
`;

const VersionText = styled(SecondaryText)`
    display: block;
    font-size: 1.1rem;
    color: #999;
`;

const Footer = () => {
    const {state} = useContext(GlobalContext);
    const {t} = useTranslation();
    const location = useLocation();
    const { language } = state.language;
    let redirectUrl;
    const { backendVersion, frontendVersion, currentYear } = useVersions(config.apiUrl);

    if (state.user.isLoaded && state.product.data) {
        redirectUrl = createRedirectUrl(state.user.data, false, state.product.data, location);
    } else if (state.product.data) {
        redirectUrl = createRedirectUrl(parseSearchParams(location), false, state.product.data, location);
    } else {
        redirectUrl = '#';
    }

    return <FooterContainer>
        <SecondaryTextContainer>
            <FooterText>{t('text6')}</FooterText>
        </SecondaryTextContainer>
        <PrivacyNoticeContainer>
            <Link to={`/privacy_notice?language=${language}`} target={"_blank"}>
                <FooterText id="privacyNotice">{t('text13')}</FooterText>
            </Link>
        </PrivacyNoticeContainer>
        <CancelLinkContainer>
            <CancelLink href={redirectUrl}>
                <FooterText id="cancelLink">{t('text7')}</FooterText>
            </CancelLink>
        </CancelLinkContainer>
        <LanguageContainer>
            <LanguageSelector/>
        </LanguageContainer>
        <VersionInfoContainer>
            <VersionText>BE: {backendVersion} FE: {frontendVersion}</VersionText>
            <VersionText>© {currentYear} Orange</VersionText>
        </VersionInfoContainer>
    </FooterContainer>
};

export default Footer;
