import styled from "styled-components";
import React, {useContext} from "react";
import {GlobalContext} from "../../stores/GlobalStore";

const LanguageList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: black;
`;

const ListItem = styled.li`
    padding: 1rem;
    display: flex;
    cursor: pointer;
    font-size: 1rem;
`;

const LanguageSelector = () => {
    const {state, dispatch} = useContext(GlobalContext);
    const {language} = state.language;

    const setLanguageUserAction = (lang) => {
        dispatch({
            type: "SET_LANGUAGE_USER_ACTION",
            payload: lang
        });
    }

    return <LanguageList>
        <ListItem id="languageChanger" onClick={() => setLanguageUserAction(language === 'fr' ? 'nl' : 'fr')}>
            {language === 'fr' ? 'Nederlands' : 'Français'}
        </ListItem>
    </LanguageList>
}

export default LanguageSelector;
