import React, {useContext} from 'react';
import {ContentPopup, ErrorIcon, HeaderPopup, Text} from "../../components/commons/Commons";
import Popup from "../../components/Popup";
import {GlobalContext} from "../../stores/GlobalStore";
import createRedirectUrl from "../../common/createRedirectUrl";
import {useLocation} from "react-router-dom";
import parseSearchParams from "../../common/parseSearchParams";
import config from "../../components/config/config";

const ErrorPopup = (props) => {
        const {state} = useContext(GlobalContext);
        const {user, product} = state;
        const location = useLocation();
        const userData = user.isLoaded ? user.data : parseSearchParams(location);
        const redirectUrl = createRedirectUrl(userData, false, product.data, location);

        const onOpen = () => {
            console.log("Error open");
            setTimeout(() => {
                console.log("Redirect url " + redirectUrl);
                window.location = redirectUrl;
            }, config.popUpDisplayTime);
        };

        return (
            <Popup
                open={props.isOpen}
                onOpen={onOpen}
                modal
                closeOnDocumentClick={false}
                error
            >
                <div className="modal">
                    <HeaderPopup>
                        <ErrorIcon/>
                    </HeaderPopup>
                    <ContentPopup>
                        <Text>
                            {props.message}
                        </Text>
                    </ContentPopup>
                </div>
            </Popup>
        )
    }
;

export default ErrorPopup;
