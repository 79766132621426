const parseSearchParams = (location) => {
    const searchParams = new URLSearchParams(location.search);

    return {
        serviceId: searchParams.get("serviceId"),
        partnerData: searchParams.get("partnerData")
    }
}

export default parseSearchParams;
