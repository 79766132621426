import {GlobalContext} from "../GlobalStore";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const VALID_LANGUAGES = ['fr', 'nl'];

const findTokenLanguage = parsedToken => {
    return parsedToken ? filterOnlyValidLanguage(parsedToken.language) : null;
}

const filterOnlyValidLanguage = language => {
    if (!language) {
        return null;
    }
    const lang = language.toLowerCase();
    return VALID_LANGUAGES.includes(lang) ? lang : null;
}
//searching the language fist from token, then from language param, then returns the default fr language
const findLanguage = (location, parsedToken) => {
    const searchParams = new URLSearchParams(location.search);
    const tokenLang = findTokenLanguage(parsedToken);
    if (tokenLang) {
        return tokenLang;
    }
    const urlLang = filterOnlyValidLanguage(searchParams.get("language"))
    if (urlLang) {
        return urlLang;
    }
    return 'fr';
}

const LanguageSetter = (props) => {
    const {i18n} = useTranslation();
    const location = useLocation();
    const {state, dispatch} = useContext(GlobalContext);
    const [isInitialized, setIsInitialized] = useState(false);
    const {language} = state.language;
    const {user} = state;

    // initialize the language
    useEffect(() => {
        const lang = findLanguage(location, user.isLoaded ? user.data : null);
        dispatch({
            type: "SET_LANGUAGE_URL_ACTION",
            payload: lang
        });
        i18n.changeLanguage(lang).then(() => {
            setIsInitialized(true);
        })
    }, [dispatch, location, user.isLoaded, user.data, i18n]);

    // detect language changes
    useEffect(() => {
        // ignore language changes made by setting the language from URL or token
        if (isInitialized && i18n.language !== language) {
            i18n.changeLanguage(language).then();
        }
    }, [isInitialized, language, i18n]);

    return isInitialized ? <>{props.children}</> : null;
}

export default LanguageSetter;
