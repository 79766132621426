import {postNoAuth} from "../../common/fetchWrapper";

const generateOtp = async (msisdn, serviceId, language = null) => {
    return postNoAuth(`/challenges/generate`, {
        msisdn: normalizeMsisdn(msisdn), serviceId, language
    });
};

const confirmOtp = async ({
                              msisdn,
                              serviceId,
                              confirmationCode,
                              challengeId,
                              redirectURI,
                              errorURI,
                              partnerData,
                              language
                          }) => {
    return postNoAuth('/challenges/check', {
        msisdn: normalizeMsisdn(msisdn),
        serviceId,
        confirmationCode,
        challengeId,
        redirectURI,
        errorURI,
        partnerData,
        language
    })
};

const normalizeMsisdn = (msisdn) => {
    if (msisdn.startsWith("0")) {
        msisdn = msisdn.substr(1);
    }
    if (msisdn.length === 9) {
        msisdn = "32" + msisdn;
    }
    if (msisdn.length === 11) {
        msisdn = "+" + msisdn;
    }
    if (msisdn.length === 12 && msisdn.startsWith("+")) {
        msisdn = "tel:" + msisdn;
    }
    return msisdn;
}

export {
    generateOtp,
    confirmOtp
}
