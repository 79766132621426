import combineReducers from 'react-combine-reducers';
import React, {createContext, useMemo, useReducer} from "react";
import {userReducer, userState} from './user/UserStore';
import {productReducer, productState} from "./product/ProductStore";
import {languageReducer, languageState} from "./language/LanguageStore";
import {errorReducer, errorState} from "./error/ErrorStore";

const [globalReducer, initialState] = combineReducers({
    user: [userReducer, userState],
    product: [productReducer, productState],
    language: [languageReducer, languageState],
    error: [errorReducer, errorState],
});

const GlobalContext = createContext(initialState);
const { Provider } = GlobalContext;

const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(globalReducer, initialState);
    const contextValue = useMemo(() => {
        return {
            state,
            dispatch
        };
    }, [state, dispatch]);
    return <Provider value={contextValue}>{children}</Provider>;
};

export { GlobalContext, StateProvider };
