import {post} from "../../common/fetchWrapper";

const makePayment = async (product, userToken, language, antiFraudTransactionId) => {
    if (product && product.data && product.data.productType === 'SUBSCRIPTION') {
        return post('/obe/subscriptions', userToken, {transactionId: antiFraudTransactionId}, language);
    } else { // ONEOFF
        return post('/obe/transactions', userToken, {transactionId: antiFraudTransactionId}, language);
    }
};

export {
    makePayment
};
