const initialState = {
    isError: false,
    message: ""
};
const errorReducer = (state, action) => {
    if (action.type === 'SET_ERROR') {
        console.log("error set: ", action.payload.message)
        return {
            isError: true,
            message: action.payload.message,
        };
    } else {
        return state;
    }
}

export { initialState as errorState, errorReducer }
