import React, {useContext, useState} from "react";
import {GlobalContext} from "../../stores/GlobalStore";
import {useTranslation} from "react-i18next";
import {
    ActionButton,
    Container,
    Content,
    PriceText, PromoText,
    SecondaryTextBlack,
    SecondaryTextBlackBold
} from "../../components/commons/Commons";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PaymentPopups from "../paymentPopups/PaymentPopups";
import {makePayment} from "../../stores/payment/PaymentService";
import getPeriodicityText from "../../common/getPeriodicityText";
import errorCodeToMessage from "./errorCodeToMessage";
import styled from "styled-components";

const PriceTextWrapper = styled(PriceText)`
    margin-bottom: 1rem;
`;

const PaymentView = () => {
    const {state, dispatch} = useContext(GlobalContext);
    const {user, product, error} = state;
    const {t, i18n} = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const {language} = state.language;


    const confirmPayment = async () => {
        setIsProcessing(true);
        const paymentResponse = await makePayment(product, user.token, language);
        if (paymentResponse.status === 201) {
            setSuccessModalOpen(true);
            setIsProcessing(false)
        } else {
            setIsProcessing(false);
            const errorJson = await paymentResponse.json();
            const errorCode = errorJson.errorCode;
            const externalErrorCode = errorJson.params?.externalErrorCode;
            dispatch({
                type: "SET_ERROR",
                payload: {message: errorCodeToMessage(t, externalErrorCode || errorCode)}
            });
        }
    };

    const isOneoff = () => product.data.productType === "ONEOFF";
    const isSubscription = () => product.data.productType === "SUBSCRIPTION";

    if (product.isLoaded) {
        const userMsisdn = user.data.sub.substr(2);
        return <Container>
            <Header product={product.data} i18n={i18n}/>
            <Content>
                <PriceTextWrapper>{product.data.amount} {product.data.currency === "EUR" ? '€' : product.data.currency} {t('VAT included')} {isSubscription() ? getPeriodicityText(t, product.data.periodicity) : ""}</PriceTextWrapper>
                <PromoText>{product.data.textPromo
                    ?.find(description => description.language.toUpperCase() === i18n.language.toUpperCase())
                    ?.content}
                </PromoText>

                {isOneoff() && (
                    <SecondaryTextBlack>
                        {t('text9.oneoff')}<SecondaryTextBlackBold>{"0" + userMsisdn}</SecondaryTextBlackBold>
                    </SecondaryTextBlack>
                )}
                {isSubscription() && (
                    <SecondaryTextBlack>
                        {t('text9.subscription')}<SecondaryTextBlackBold>{"0" + userMsisdn}</SecondaryTextBlackBold>
                    </SecondaryTextBlack>
                )}
                <ActionButton
                    id="confirmPaymentButton"
                    disabled={isProcessing}
                    onClick={() => confirmPayment()}
                >{t('confirmButton')}</ActionButton>
                {isSubscription() && (
                    <SecondaryTextBlack>{t('text4.subscription')}</SecondaryTextBlack>
                )}
            </Content>
            <Footer/>
            <PaymentPopups isSuccess={isSuccessModalOpen} isError={error.isError} errorMessage={error.message}/>
        </Container>
    }
    return <Container>
        <PaymentPopups isSuccess={isSuccessModalOpen} isError={error.isError}
                       errorMessage={error.message}/>
    </Container>;
};

export default PaymentView;
